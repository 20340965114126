import React, { useState, memo, useEffect } from "react";
import Pagination from "./Pagination";
import DonutChart from "react-donut-chart";

function EarnedPoints({ pointData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const filteredRecords = pointData?.records?.filter(
    (record) => record.kk_amount > 0 || record.ks_amount > 0
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredRecords?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (!pointData) return;
    setPieData([
      {
        label: `Kaleseramik Puan`,
        value: Number(pointData?.groups?.ksPoint),
      },
      {
        label: "Anketler",
        value: Number(
          Number(pointData?.groups?.survey?.kk) +
            Number(pointData?.groups?.survey?.ks)
        ),
      },
      {
        label: `Kalekim Puan`,
        value: Number(pointData?.groups?.kkPoint),
      },
      {
        label: "Video İzleme",
        value: Number(
          Number(pointData?.groups?.liveBroadcast?.kk) +
            Number(pointData?.groups?.liveBroadcast?.ks)
        ),
      },
      {
        label: "Yarışma",
        value: Number(
          Number(pointData?.groups?.exam?.kk) +
            Number(pointData?.groups?.exam?.ks)
        ),
      },

      {
        label: "Online Olma ve Site Deneyimi",
        value: Number(
          Number(pointData?.groups?.beingOnline?.kk) +
            Number(pointData?.groups?.beingOnline?.ks)
        ),
      },
      {
        label: "Manuel Puan Müdahelesi",
        value: Number(
          Number(pointData?.groups?.manuel?.kk) +
            Number(pointData?.groups?.manuel?.ks)
        ),
      },
    ]);
  }, [pointData]);

  return (
    <div>
      <div className="flex flex-col px-4 lg:px-0">
        <div className="flex flex-col gap-12 lg:flex-row">
          <div className="flex flex-col w-full max-w-4xl mx-auto mb-16 space-y-4 md:flex-row cdc-container justify-evenly">
            <div className="flex flex-row space-x-24">
              <div className="flex flex-col relative">
                <div className="cdc-overlay z-50 w-[325px] h-[325px]">
                  <div className="cdc-text">
                    <div className="space-y-4">
                      <p className="text-body-s font-sm">Toplam</p>
                      <p className="text-lg font-lg">
                        {(
                          Number(pointData?.groups?.ksPoint) +
                          Number(pointData?.groups?.kkPoint)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </p>
                      <p className="text-body-s text-secondary-secondary">
                        Kale Puan
                      </p>
                    </div>
                  </div>
                </div>
                <DonutChart
                  data={pieData}
                  interactive={false}
                  // selectedOffset={false}
                  colors={[
                    "#E94034",
                    "#39ADF7",
                    "#6258C9",
                    "#be14d9",
                    "#57AF6A",
                    "#CDDB5F",
                    "#818285",
                  ]}
                  legend={false}
                  width={325}
                  height={325}
                  strokeColor="transparent"
                  className="p-0"
                />
                {/* <p className="mt-8 text-body-s text-secondary-secondary">
                  Son Kullanım Tarihi; 01.12.2021
                </p> */}
              </div>

              <div className="flex flex-col justify-start ml-4 space-y-4 cdc-legend pt-6 w-96">
                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-red"></span>
                  <span>
                    Kaleseramik Puan{" "}
                    <b>
                      {pointData?.groups?.ksPoint
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-blue"></span>
                  <span>
                    Anketler{" "}
                    <b>
                      {(
                        pointData?.groups?.survey?.ks +
                        pointData?.groups?.survey?.kk
                      )
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                    {` (KS ${pointData?.groups?.survey?.ks
                      .toString()
                      .replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}, KK ${pointData?.groups?.survey?.kk
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")})`}
                  </span>
                </span>

                <span
                  title="28 (28.000000000000004%)"
                  className="cdc-legend-item"
                >
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-purple"></span>
                  <span className="">
                    Kalekim Puan{" "}
                    <b>
                      {pointData?.groups?.kkPoint
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>
                <span title="24 (24%)" className="cdc-legend-item">
                  <span
                    className="rounded-full cdc-legend-item-color"
                    style={{
                      backgroundColor: "#be14d9",
                    }}
                  ></span>
                  <span>
                    Video İzleme{" "}
                    <b>
                      {(
                        pointData?.groups?.liveBroadcast?.ks +
                        pointData?.groups?.liveBroadcast?.kk
                      )
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                    {` (KS ${pointData?.groups?.liveBroadcast?.ks

                      .toString()
                      .replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}, KK ${pointData?.groups?.liveBroadcast?.kk
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")})`}
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-green"></span>
                  <span>
                    Yarışma{" "}
                    <b>
                      {(
                        pointData?.groups?.exam?.ks +
                        pointData?.groups?.exam?.kk
                      )
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                    {` (KS ${pointData?.groups?.exam?.ks
                      .toString()
                      .replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}, KK ${pointData?.groups?.exam?.kk
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")})`}
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-yellow"></span>
                  <span>
                    Online Olma ve Site Deneyimi{" "}
                    <b>
                      {(
                        pointData?.groups?.beingOnline?.ks +
                        pointData?.groups?.beingOnline?.kk
                      )
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                    {` (KS ${pointData?.groups?.beingOnline?.ks
                      .toString()
                      .replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}, KK ${pointData?.groups?.beingOnline?.kk
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")})`}
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-gray"></span>
                  <span>
                    Manuel Puan
                    <b>
                      {" "}
                      {(
                        pointData?.groups?.manuel?.ks +
                        pointData?.groups?.manuel?.kk
                      )
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                    {` (KS ${pointData?.groups?.manuel?.ks
                      .toString()
                      .replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}, KK ${pointData?.groups?.manuel?.kk
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")})`}
                  </span>
                </span>
                <p className="text-secondary-secondary">
                  KS; Kaleseramik | KK; Kalekim
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="bg-secondary-secondary-bg">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-20"
                    >
                      TARİH
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left uppercase font-md text-body-l text-primary-black"
                    >
                      PUAN TÜRÜ
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-20"
                    >
                      PUAN
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-[50%]"
                    >
                      AÇIKLAMA
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((record, index) => {
                    if (
                      typeof record.kk_amount !== "number" ||
                      typeof record.ks_amount !== "number" ||
                      (record.resource === "Manuel" &&
                        record.type === "negative")
                    )
                      //eslint-disable-next-line
                      return;
                    return (
                      <tr
                        className="bg-white border-b border-secondary-lines"
                        key={index}
                      >
                        <td className="px-6 py-2 text-center text-body text-secondary-primary">
                          {new Date(record.createdAt).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-2 text-body text-secondary-primary">
                          {record.description.includes("Manuel Puan Müdahalesi")
                            ? "Manuel Puan"
                            : record.description}
                        </td>

                        <td className="px-6 py-2 text-center text-body text-secondary-primary">
                          <p style={{ width: "150px", textAlign: "center" }}>
                            {(record.kk_amount + record.ks_amount)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </p>
                          <p
                            style={{
                              width: "75px",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            KK: {record.kk_amount}
                          </p>
                          <p
                            style={{
                              width: "75px",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            KS: {record.ks_amount}
                          </p>
                        </td>
                        <td className="px-6 py-2 text-center text-body text-secondary-primary">
                          {record.resource === "Manuel"
                            ? record.reason || "-"
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        totalItems={pointData?.records?.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
export default memo(EarnedPoints);
