import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../services/authService";
import { useSelector, useDispatch } from "react-redux";
import { SET_USER } from "../../../store/app/appSlice";

const Profile = ({ profileModal }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.app.user);

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(SET_USER(null));
      navigate("/", { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      ref={profileModal}
      className="fixed top-0 left-0 z-10 w-full h-screen transform bg-white shadow-lg lg:left-auto lg:top-auto lg:absolute lg:h-auto lg:max-w-sm lg:right-0"
    >
      <div className="" role="none">
        <div className="flex flex-col bg-background-teal px-6 py-4 justify-between items-center">
          <div className="relative flex items-center justify-center w-20 h-20 rounded-full bg-secondary-titles">
            {user?.profile_image?.url ? (
              <Link to={`/user/${user.objectId}`}>
                <img
                  className="w-20 h-20 rounded-full object-cover object-center"
                  src={user?.profile_image?.url}
                  alt="profile-img"
                />
              </Link>
            ) : (
              <>
                <span className="sr-only">User</span>
                <svg role="img" className="w-8 h-8 text-white">
                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                </svg>
              </>
            )}
          </div>
          <h3 className="mt-2 text-xs font-sm">{user?.Name}</h3>
        </div>
        <div className="flex flex-col overflow-y-auto overscroll-contain h-max-calc-16 lg:max-h-[23rem]">
          <Link className="flex px-4" to="/profile/update-profile">
            <div className="flex items-center w-full py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg role="img" className="w-6 link-icon text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#edit" />
                </svg>
              </div>
              <div>
                <h4 className="font-sans uppercase text-body">
                  BİLGİ GÜNCELLEME
                </h4>
              </div>
            </div>
          </Link>
          <Link className="flex px-4" to="/change-password">
            <div className="flex items-center w-full py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg role="img" className="w-6 link-icon text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#lock" />
                </svg>
              </div>
              <div>
                <h4 className="font-sans uppercase text-body">
                  ŞİFRE DEĞİŞTİR
                </h4>
              </div>
            </div>
          </Link>
          <Link to="/points" className="flex px-4">
            <div className="flex items-center w-full py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg role="img" className="w-6 link-icon text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#pie" />
                </svg>
              </div>
              <div>
                <h4 className="font-sans uppercase text-body">PUANLARIM</h4>
              </div>
            </div>
          </Link>
          <Link to="/profile/surveys" className="flex px-4">
            <div className="flex items-center w-full py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg role="img" className="w-6 link-icon text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#survey" />
                </svg>
              </div>
              <div>
                <h4 className="font-sans uppercase text-body">ANKETLERİM</h4>
              </div>
            </div>
          </Link>
          <Link to="/information-cube" className="flex px-4">
            <div className="flex items-center w-full py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg role="img" className="w-6 link-icon text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#competition" />
                </svg>
              </div>
              <div>
                <h4 className="font-sans uppercase text-body">Yarışma</h4>
              </div>
            </div>
          </Link>
          <div className="flex px-4" onClick={handleLogout}>
            <div className="flex items-center w-full py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-background-red">
                <svg role="img" className="w-5 link-icon text-primary-red">
                  <use xlinkHref="/assets/img/necromancers.svg#logout" />
                </svg>
              </div>
              <div>
                <button className="font-sans uppercase text-body">
                  Çıkış Yap
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
